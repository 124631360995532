import { useEffect, useState } from "react"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/"

import postAddressUtil from "utils/postAddressUtil"

import Typography from "common/Typography"
import { Address } from "models/User"
import { CustomVariant } from "theme/typography"

type PostalAddressProps = {
  addressData: Address
  companyName?: string
  addressType?: "default" | "personal" | "business"
  variant?: CustomVariant
  inline?: boolean
}

const PostalAddressFormat: React.FC<PostalAddressProps> = ({
  addressData,
  companyName = "",
  addressType = "default",
  variant = "body1",
  inline = false
}) => {
  const inlineStyle = inline
  const classes = useStyles()
  const [address, setAddress] = useState()

  useEffect(
    () => {
      const addr = postAddressUtil(addressData, companyName, addressType)
      setAddress(addr)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressData]
  )

  return (
    <Typography
      variant={variant}
      className={classNames({ [classes.root]: !inlineStyle })}
    >
      {address}
    </Typography>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: "break-spaces"
  }
}))

export default PostalAddressFormat
