import i18nCountries from "i18n-iso-countries"
import PostalAddress from "i18n-postal-address"

import { Address } from "models/User"

const postalAddress = new PostalAddress()

const addCommaAfter = (value: string) => `${value},`
const upperCase = (value: string) => value.toUpperCase()
const lowerCase = (value: string) => value.toLowerCase()
const capitalize = (value: string) =>
  value.toLowerCase().replace(/(?:^|\s)\S/g, (v) => v.toUpperCase())

const addAddressFormat = (countryCode: string) => {
  const frm = addressOverrides(countryCode)
  postalAddress.addFormat({
    country: countryCode,
    format: frm,
    parser: "array",
    type: "default"
  })
}

const postAddressUtil = (
  address: Address,
  companyName?: string,
  addressType?: "business" | "personal" | "default",
  asArray: boolean = false
) => {
  if (!(address.countryCode in postalAddress.addressFormats)) {
    addAddressFormat(address.countryCode)
  }

  postalAddress.formatForCountry = address.countryCode
  postalAddress
    .setCompanyName(companyName)
    .setAddress1(address.streetAddress)
    .setCity(address.city)
    .setFirstName(address.firstName)
    .setLastName(address.lastName)
    .setPostalCode(address.zipCode)
    .setCountry(i18nCountries.getName(address.countryCode, "en"))
    .setOutputFormat("array")
    .setFormat({
      country: address.countryCode,
      type: addressType,
      useTransforms: true
    })

  return asArray ? postalAddress.output() : postalAddress.toString()
}

const addressOverrides = (countryCode: string) => {
  switch (countryCode) {
    case "GB":
      return [
        ["postalCode", { attribute: "city", transforms: [capitalize] }],
        ["firstName", "lastName"],
        [{ attribute: "country", transforms: [capitalize] }]
      ]
  }
}

export default postAddressUtil
